<template>
    <div class="hamburger-menu hlaml-hamburger-menu">
        <BaseButton
            :class="{ 'open': mainMenuOpen }"
            type="button"
            class="hamburger-menu__button base-button--transparent-light"
            icon-position="center"
            aria-label="Open menu"
            @click="hlamlStore.toggleMainMenu()"
        >
            <i class="icon-menu">
                <span />
                <span />
                <span />
                <span />
                <span />
            </i>
        </BaseButton>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useHlamlStore } from '~/store/hlaml';

const hlamlStore = useHlamlStore();
const { mainMenuOpen } = storeToRefs(hlamlStore);
</script>

<style lang="less" src="./HlamlHamburgerMenu.less"></style>
