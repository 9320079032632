<template>
    <DnModal
        class="menu-overlay hlaml-menu-overlay"
        transition-name="hlaml-menu"
        :is-visible="hlamlStore.isMainMenuOpen"
        :hide-default-close-button="true"
        :prevent-body-scroll="true"
        @close="hlamlStore.closeMainMenu"
    >
        <div class="hlaml-menu-overlay__container">
            <NuxtLink
                class="hlaml-menu-overlay__logo"
                to="/hetluktalleenmetlogistiek"
            >
                <HlamlLogo height="64" />
            </NuxtLink>

            <menu
                v-if="primaryMenu && primaryMenu.length"
                class="hlaml-navigation-links-primary"
            >
                <li
                    v-for="(link, index) in primaryMenu"
                    :key="`primary-menu-${index}`"
                    :data-text="link.linkField.text"
                >
                    <CraftLink :link="link.linkField">
                        {{ link.linkField.text }}
                    </CraftLink>
                </li>
            </menu>

            <menu
                v-if="secondaryMenu && secondaryMenu.length"
                class="hlaml-navigation-links-secondary"
            >
                <li
                    v-for="(link, index) in secondaryMenu"
                    :key="`primary-menu-${index}`"
                    :data-text="link.linkField.text"
                >
                    <CraftLink :link="link.linkField">
                        {{ link.linkField.text }}
                    </CraftLink>
                </li>
            </menu>
        </div>
    </DnModal>
</template>

<script setup>
import DnModal from '@digitalnatives/modal';
import { useHlamlStore } from '~/store/hlaml';

const hlamlStore = useHlamlStore();
const route = useRoute();

watch(() => route.path, () => {
    hlamlStore.closeMainMenu();
});

const primaryMenu = computed(() => {
    return hlamlStore.primaryMenu;
});

const secondaryMenu = computed(() => {
    return hlamlStore.secondaryMenu;
});
</script>

<style lang="less" src="./HlamlMenuOverlay.less"></style>
