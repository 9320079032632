<template>
    <DelayHydration>
        <footer
            class="hlaml-footer"
        >
            <div class="hlaml-footer__container">
                <img
                    class="hlaml-footer__swirl"
                    src="~/assets/img/hlaml/swirl-footer.png"
                    role="presentation"
                >

                <div
                    class="hlaml-footer__content"
                >
                    <NuxtLink
                        class="hlaml-footer__logo"
                        to="/hetluktalleenmetlogistiek"
                    >
                        <HlamlLogo height="96" />
                    </NuxtLink>

                    <NuxtLink
                        to="/"
                        class="hlaml-footer__initiative"
                    >
                        <p>
                            Een initiatief van <span>Transport en Logistiek Nederland</span>
                        </p>

                        <div
                            class="hlaml-footer__initiative-logo"
                        >
                            <img
                                src="~/assets/img/logo.svg"
                                alt="TLN Logo"
                            >
                        </div>
                    </NuxtLink>
                </div>
            </div>
        </footer>
    </DelayHydration>
</template>

<script setup>
</script>

<style lang="less" src="./HlamlFooter.less" />
