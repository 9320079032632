<template>
    <nav class="hlaml-navigation-bar">
        <menu class="hlaml-navigation-bar__menu">
            <li>
                <BaseButton
                    to="/hetluktalleenmetlogistiek/vacatures"
                    :element="NuxtLink"
                    class="base-button--link"
                    size="large"
                >
                    Vacatures
                </BaseButton>
            </li>
            <li>
                <BaseButton
                    to="/hetluktalleenmetlogistiek/opleidingen"
                    :element="NuxtLink"
                    class="base-button--link"
                    size="large"
                >
                    Opleidingen
                </BaseButton>
            </li>
            <li>
                <BaseButton
                    to="/hetluktalleenmetlogistiek/doe-mee"
                    :element="NuxtLink"
                    class="base-button--quaternary"
                    size="large"
                >
                    Doe mee
                </BaseButton>
            </li>
        </menu>
    </nav>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
</script>

<style lang="less" src="./HlamlNavBar.less"></style>
