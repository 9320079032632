<template>
    <div class="layout--hlaml">
        <HlamlNavBar />
        <HlamlMenuOverlay />
        <HlamlHamburgerMenu />

        <slot />

        <HlamlFooter />
    </div>
</template>

<script setup>
import HlamlGlobalData from '~/graphql/queries/hlaml/GlobalData.graphql';
import { useHlamlStore } from '~/store/hlaml';

const hlamlStore = useHlamlStore();

const { data: globalData } = await useAsyncQuery({
    query: HlamlGlobalData
});

hlamlStore.setGlobalData(toValue(globalData));

useHead({
    htmlAttrs: {
        lang: 'nl'
    },
    bodyAttrs: {
        'menu-open': hlamlStore.isMainMenuOpen ? 'true' : 'false',
    },
    link: [
        {
            rel: 'icon',
            type: 'image/x-icon',
            href: '/favicons/hlaml.ico'
        }
    ],
});
</script>

<style lang="less">
.layout--hlaml {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
    min-height: 100vh;
}
</style>
